@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&family=Dancing+Script&display=swap');

.header {
  height: 70px;
  display: flex;
  align-items: center;
  background-color: teal;
  position: sticky;
  top: 0;
  z-index: 100;
}

.logo__text {
  color: white;
  font-size: 30px;
  font-family: 'Comfortaa';
  margin-right: 10px;
}

.header__logo {
  width: 60px;
  object-fit: contain;
  margin: 0 15px;
}

.logo_small {
  font-size: 12px;
  padding-top: 3px;
  display: flex;
}

.logo__text {
  color: white;
  font-size: 24px;
  font-family: 'Comfortaa';
  margin-right: 10px;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  color: white;
}

.group {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  font-size: 20px;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu>li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.burger {
  display: none;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .header {
    box-sizing: border-box;
    width: 390px;
    height: 60px;
    /* border: solid red 2px; */
  }

  .header__option {
    display: none;
  }

  .header__logo {
    width: 40px;
    margin-left: 15px;
  }

  .logo__text {
    color: white;
    font-size: 18px;
    font-family: 'Comfortaa';
    margin-right: 10px;
  }

  /**/

  .menu-button-container {
    display: flex;
  }

  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #menu-toggle~.menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #menu-toggle:checked~.menu li {
    border: 1.5px solid #17EEC3;
    height: 5em;
    padding: 1.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .menu>li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: 390px;
  }

  .menu>li:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .menu-button {
    margin-right: 8%;
  }

  .burger {
    display: block;
  }

  .burger-link {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    font-size: 1.1rem;
    color: white;
  }

  .menu>li {
    text-align: center;
  }

  .header {
    position: fixed;
    width: 100%
  }
}